import * as React from "react"
import styled from "styled-components"

const Container = styled.div`
    text-align: center;
`

function Title({ children }) {
    return (
        <Container>
            {children}
        </Container>
    )
}

export default Title