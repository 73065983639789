import * as React from "react"
import Layout from "../components/Layout/Layout"
import Title from "../components/Title/Title"
import styled from "styled-components"

const StyledSection = styled.section`
    width: min(90%, 1600px);
    margin: auto;
    padding-bottom: var(--space-5);

    hr {
        margin-bottom: var(--space-4);
    }
`

const PaletteRow = styled.div`
    display: flex;
`

const StyledPanel = styled.div`
    flex-basis: 100px;
    flex-grow: 1;
    height: ${props => props.spacePanel ? `var(--space-4)` : `calc(var(--space-6) *2)`};
    box-shadow: ${props => props.shadow || "var(--shadow-elevation-medium)"};
    margin-right: var(--space-3);
    margin-bottom: ${props => props.spacePanel && `var(--space-1)`};
    transition: 300ms;
    max-width: 100%;

    &:hover {
        transform: translateY(calc(var(--space-1) * -1));
        box-shadow: var(--shadow-elevation-high);
    }
`

function VisLangSection({ heading, description, children }) {
    return (
        <StyledSection>
            <h2>{heading}</h2>
            <p>{description}</p>
            <hr />
            {children}
        </StyledSection>
    )
}

function TypeScale({ size }) {
    return (
        <p style={{fontSize: `var(--type-${size})`, lineHeight: 1.2, margin: 'var(--space-4) 0'}}>The quick brown fox jumps over the lazy dog.</p>
    )
}

function Panel({ color, shadow, width, spacePanel }) {
    return (
        <StyledPanel style={{backgroundColor: `${color}`, width: `${width}`}} shadow={shadow} spacePanel={spacePanel} />
    )
}

function VisualLanguage() {
    return (
        <Layout pageTitle="Visual Language">
            <section>
                <div style={{width: 'min(90%, 1000px)', margin: 'auto'}}>
                    <Title>
                        <h1>Visual Language</h1>
                        <p>A guide to the systematisation of visual elements used to create a consistent, coherent design.</p>
                    </Title>
                </div>
            </section>
            <VisLangSection
                heading="Type Scale"
                description="The type scale consists of 7 font sizes, each of which is a calculation using the clamp() function. Larger sizes scale up and down more than smaller sizes."
            >
                <TypeScale size="xxl" />
                <TypeScale size="xl" />
                <TypeScale size="l" />
                <TypeScale size="m" />
                <TypeScale size="s" />
                <TypeScale size="xs" />
                <TypeScale size="xxs" />
            </VisLangSection>
            <VisLangSection
                heading="Colour Palette"
                description="The colour palette consists of a set of shades of primary, secondary, tertiary & neutral colours. Each is on a scale that starts at 50 and ends at 900 with intermediate shades distributed between those values."
            >
                <h3>Primary</h3>
                <PaletteRow>
                    <Panel color="var(--primary-50)" />
                    <Panel color="var(--primary-100)" />
                    <Panel color="var(--primary-200)" />
                    <Panel color="var(--primary-300)" />
                    <Panel color="var(--primary-400)" />
                    <Panel color="var(--primary-500)" />
                    <Panel color="var(--primary-600)" />
                    <Panel color="var(--primary-700)" />
                    <Panel color="var(--primary-800)" />
                    <Panel color="var(--primary-900)" />
                </PaletteRow>
                <h3>Secondary</h3>
                <PaletteRow>
                    <Panel color="var(--secondary-50)" />
                    <Panel color="var(--secondary-100)" />
                    <Panel color="var(--secondary-200)" />
                    <Panel color="var(--secondary-300)" />
                    <Panel color="var(--secondary-400)" />
                    <Panel color="var(--secondary-500)" />
                    <Panel color="var(--secondary-600)" />
                    <Panel color="var(--secondary-700)" />
                    <Panel color="var(--secondary-800)" />
                    <Panel color="var(--secondary-900)" />
                </PaletteRow>
                <h3>Tertiary</h3>
                <PaletteRow>
                    <Panel color="var(--tertiary-50)" />
                    <Panel color="var(--tertiary-100)" />
                    <Panel color="var(--tertiary-200)" />
                    <Panel color="var(--tertiary-300)" />
                    <Panel color="var(--tertiary-400)" />
                    <Panel color="var(--tertiary-500)" />
                    <Panel color="var(--tertiary-600)" />
                    <Panel color="var(--tertiary-700)" />
                    <Panel color="var(--tertiary-800)" />
                    <Panel color="var(--tertiary-900)" />
                </PaletteRow>
                <h3>Neutral</h3>
                <PaletteRow>
                    <Panel color="var(--neutral-50)" />
                    <Panel color="var(--neutral-100)" />
                    <Panel color="var(--neutral-200)" />
                    <Panel color="var(--neutral-300)" />
                    <Panel color="var(--neutral-400)" />
                    <Panel color="var(--neutral-500)" />
                    <Panel color="var(--neutral-600)" />
                    <Panel color="var(--neutral-700)" />
                    <Panel color="var(--neutral-800)" />
                    <Panel color="var(--neutral-900)" />
                </PaletteRow>
            </VisLangSection>
            <VisLangSection
                heading="Shadow Palette"
                description="There are 3 box shadows to represent different levels of elevation from the page."
            >
                <PaletteRow>
                    <Panel color="var(--neutral-400)" shadow="var(--shadow-elevation-low)" />
                    <Panel color="var(--neutral-400)" shadow="var(--shadow-elevation-medium)" />
                    <Panel color="var(--neutral-400)" shadow="var(--shadow-elevation-high)" />
                </PaletteRow>
            </VisLangSection>
            <VisLangSection
                heading="Spacing & Sizing Scale"
                description="There are 2 scales - responsive and fixed. The first defines spacing and sizing options that adjust to the size of the screen, the second is a scale that is fixed across all screen sizes."
            >
                <h3>Responsive</h3>
                <Panel width="var(--space-1)" color="var(--primary-500)" spacePanel={true} />
                <Panel width="var(--space-2)" color="var(--primary-500)" spacePanel={true} />
                <Panel width="var(--space-3)" color="var(--primary-500)" spacePanel={true} />
                <Panel width="var(--space-4)" color="var(--primary-500)" spacePanel={true} />
                <Panel width="var(--space-5)" color="var(--primary-500)" spacePanel={true} />
                <Panel width="var(--space-6)" color="var(--primary-500)" spacePanel={true} />
                <Panel width="var(--space-7)" color="var(--primary-500)" spacePanel={true} />
                <Panel width="var(--space-8)" color="var(--primary-500)" spacePanel={true} />
                <Panel width="var(--space-9)" color="var(--primary-500)" spacePanel={true} />
                <Panel width="var(--space-10)" color="var(--primary-500)" spacePanel={true} />
                <Panel width="var(--space-11)" color="var(--primary-500)" spacePanel={true} />
                <Panel width="var(--space-12)" color="var(--primary-500)" spacePanel={true} />
                <Panel width="var(--space-13)" color="var(--primary-500)" spacePanel={true} />
                <Panel width="var(--space-14)" color="var(--primary-500)" spacePanel={true} />
                <Panel width="var(--space-15)" color="var(--primary-500)" spacePanel={true} />
                <Panel width="var(--space-16)" color="var(--primary-500)" spacePanel={true} />
                <h3>Fixed</h3>
                <Panel width="var(--fixed-space-1)" color="var(--primary-500)" spacePanel={true} />
                <Panel width="var(--fixed-space-2)" color="var(--primary-500)" spacePanel={true} />
                <Panel width="var(--fixed-space-3)" color="var(--primary-500)" spacePanel={true} />
                <Panel width="var(--fixed-space-4)" color="var(--primary-500)" spacePanel={true} />
                <Panel width="var(--fixed-space-5)" color="var(--primary-500)" spacePanel={true} />
                <Panel width="var(--fixed-space-6)" color="var(--primary-500)" spacePanel={true} />
                <Panel width="var(--fixed-space-7)" color="var(--primary-500)" spacePanel={true} />
                <Panel width="var(--fixed-space-8)" color="var(--primary-500)" spacePanel={true} />
                <Panel width="var(--fixed-space-9)" color="var(--primary-500)" spacePanel={true} />
                <Panel width="var(--fixed-space-10)" color="var(--primary-500)" spacePanel={true} />
                <Panel width="var(--fixed-space-11)" color="var(--primary-500)" spacePanel={true} />
                <Panel width="var(--fixed-space-12)" color="var(--primary-500)" spacePanel={true} />
                <Panel width="var(--fixed-space-13)" color="var(--primary-500)" spacePanel={true} />
                <Panel width="var(--fixed-space-14)" color="var(--primary-500)" spacePanel={true} />
                <Panel width="var(--fixed-space-15)" color="var(--primary-500)" spacePanel={true} />
                <Panel width="var(--fixed-space-16)" color="var(--primary-500)" spacePanel={true} />
            </VisLangSection>
        </Layout>
    )
}

export default VisualLanguage